import React from 'react'

import Components from 'src/storyblok/Components'

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }
    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = props.pageContext.story
    return { story }
  }

  constructor(props) {
    super(props)

    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    const story = this.state.story
    const content = this.state.story.content
    return (
      <React.Fragment>
        {React.createElement(Components(content.component), {
          storyID: story.uuid,
          name: story.name,
          slug: story.slug,
          isStartPage: story.slug === 'home',
          isRootPage: story.is_startpage,
          full_slug: story.full_slug,
          date: story.published_at,
          blok: story.content,
          footer: story.footer,
        })}
      </React.Fragment>
    )
  }
}

export default StoryblokEntry
